export function nextFrame(fn) {
  window.requestAnimationFrame(() => window.requestAnimationFrame(fn));
}

export const collapseTransition = {
  methods: {
    enter(el) {
      el.style.overflow = 'hidden'
      el.style.height = '0'

      nextFrame(() => {
        el.style.height = `${el.scrollHeight}px`
      })
    },
    leave(el) {
      el.style.overflow = 'hidden'
      el.style.height = `${el.scrollHeight}px`

      nextFrame(() => {
        el.style.height = '0'
      })
    },
    afterEnter(el) {
      el.style.height = ''
      el.style.overflow = ''
    },
    afterLeave(el) {
      el.style.height = ''
      el.style.overflow = ''
    }
  }
};