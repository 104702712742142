<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Archive List')"></tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />

      <el-form class="search_unit compact" ref="searchUnit" :rules="rules" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Update Date')" prop="processUpdateDate">
            <el-date-picker
              popper-class="process_update_date"
              v-model="searchForm.processUpdateDate"
              type="daterange"
              range-separator="to"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
              @change.native="onDateRangeChange"
              start-placeholder="Start date"
              end-placeholder="End date"
              :clearable="false"
              :pickerOptions="{
                onPick: onPick,
                disabledDate: disabledDate,
              }">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
            <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
          </el-form-item>
          <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
            <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
          </el-form-item>
          <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
            <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
            <el-input type="text" v-model="searchForm.flowName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Process')" prop="processTypes">
            <el-cascader popper-class="category-select" v-model="searchForm.processTypes" :class="{multiple_selected: multipleSelect(searchForm.processTypes)}" placeholder="Select" :options="processes" :props="{value: 'code', 'label': 'name', multiple: true}" collapse-tags clearable>
              <template slot-scope="{ data }">
                <div style="display: flex;align-items: center">
                  <tw-entity-icon v-if="data.entityId" :entityName="data.entityId" size="medium" color="color" style="margin-right: 8px;" />
                  <span>{{data.name}}</span>
                </div>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item :label="$t('Label.Process User Memo')" prop="processUserMemo">
            <el-input type="text" v-model="searchForm.processUserMemo" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Main Goods')" prop="mainGoods">
                <el-input type="text" v-model="searchForm.mainGoods" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.BC Last Updated Date')" prop="bcLastUpdatedDate">
                <el-date-picker
                  v-model="searchForm.bcLastUpdatedDate"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <!-- 2021/4 -->
              <el-form-item label=" " prop="latestVersionOnly">
                <el-checkbox border class="border_none" v-model="searchForm.latestVersionOnly" :true-label="LATEST_VERSION_ONLY_FLG.ON" :false-label="LATEST_VERSION_ONLY_FLG.OFF">{{$t('Label.Latest version only')}}</el-checkbox>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>

          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system :schemas="schemas" :items="items" :pageSize="limit" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { ARCHIVE_SEARCH_SORT_TARGET,DEFAULT_PAGENATION,LATEST_VERSION_ONLY_FLG,INITIAL_VIEW_FLG } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinEntity from '@/utils/mixinEntity.js';
import { collapseTransition } from '@/utils/nextFrame';
import { entityNames } from '@/dictionaries/map.js';
import { normalize, clearSearchForm } from '@/utils/searchUtil';
import schemas from '@/dictionaries/listArchiveSchema.json';

export default {
  name: 'ListArchive',
  mixins: [mixinEntityList, collapseTransition, mixinEntity],
  data() {
    return {
      schemas: schemas,
      items: null,
      // エンティティプルダウン
      categories: _.map(entityNames, o => {
        return {
          ...o,
          name: this.$t(`Entity.${o.nameEn}`)
        };
      }),
      // プロセスプルダウン
      processes: [],
      searchForm: {
        fromSectionIds: [],
        toSectionIds: [],
        tradingName: '',
        flowName: '',
        category: [],
        mainGoods: '',
        processUpdateDate: '',
        bcLastUpdatedDate: '',
        latestVersionOnly: LATEST_VERSION_ONLY_FLG.OFF,
        processTypes: [],
        processUserMemo: ''
      },
      LATEST_VERSION_ONLY_FLG: LATEST_VERSION_ONLY_FLG,
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
      processUpdateMinDate: null, // DatePicker Disable日付チェック用
      rules: {
        processUpdateDate: [
          {
            message: '181日間以内で入力してください',
            validator: this.checkProcessUpdateDate,
            trigger: 'change',
          }
        ],
      }
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Archive List')},
      ];
    },
  },
  created() {
    this.processes = this.getProcesses();
    console.log("this.processes:", this.processes)

    const queries = this.$store.getters.getQueries('ARCHIVE');
    if (queries) {
      this.searchForm = queries;
      if (!this.searchForm.processUpdateDate || (this.searchForm.processUpdateDate && !this.searchForm.processUpdateDate.length)) {
        this.resetProcessUpdateDate();
      }
      if (this.checkDateRange(this.searchForm.processUpdateDate[0], this.searchForm.processUpdateDate[1], 180)) {
        this.resetProcessUpdateDate();
      }
    } else {
      // プロセス更新日時 検索初期条件：Start Date 当日を含んで181日前～ End Date 当日
      this.resetProcessUpdateDate();
    }
    this.fetch();
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        fromSectionIds: f.fromSectionIds,
        toSectionIds: f.toSectionIds,
        tradingName: f.tradingName,
        flowName: f.flowName,
        mainGoods: f.mainGoods,
        processUpdateDateStart: this.getStartDate(f.processUpdateDate),
        processUpdateDateEnd: this.getEndDate(f.processUpdateDate),
        bcLastUpdatedDateStart: this.getStartDate(f.bcLastUpdatedDate),
        bcLastUpdatedDateEnd: this.getEndDate(f.bcLastUpdatedDate),
        latestVersionOnly: f.latestVersionOnly,
        processIds: f.processTypes ? _.flatten(_.map(f.processTypes, item => this.getProcessIdsByProcessTypeAndEntityCode(_.get(item, '1'), _.get(item, '0')))) : [],
        processUserMemo: f.processUserMemo,
      };

      return {
        ...queries,
        initialFlag: this.initialFlag(queries),
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      // bff_ar_1 アーカイブ一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/archives/search',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api.request(params)
      .then(res => {
        this.items = res.archiveList;
        this.totalCount = res.totalCount;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        this.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    async search() {
      await this.$refs.searchUnit.validate()
        .then(() => {
          this.offset = 0;
          this.$store.commit('SET_QUERIES', {key: 'ARCHIVE', queries: _.cloneDeep(this.searchForm)});
          this.fetch();
        })
        .catch(() => {
          this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
        })
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.searchForm.latestVersionOnly = LATEST_VERSION_ONLY_FLG.OFF;
      this.resetProcessUpdateDate();
      this.$store.commit('SET_QUERIES', {key: 'ARCHIVE', queries: _.cloneDeep(this.searchForm)});
    },
    rowClick(row) {
      setTimeout(() => {
        this.$router.push({
          name: `Archive${_.capitalize(row.processId.replace(/\d/g, ''))}`,
          params: { entityId: row.entityId, processSeq: row.processSeq, processTrx: row.processTrx, _processId: row.processId.toLowerCase() },
        });
      });
    },
    rowDbClick(row) {
      this.linkToOtherWindow({
        name: `Archive${_.capitalize(row.processId.replace(/\d/g, ''))}`,
        params: { entityId: row.entityId, processSeq: row.processSeq, processTrx: row.processTrx, _processId: row.processId.toLowerCase() },
      });
    },
    filter(node, keyword) {
      const keywords = node.pathNodes.reduce((ary, pathNode) => {
        if (Array.isArray(pathNode.data.keywords)) {
          return ary.concat(node.pathLabels).concat(pathNode.data.keywords);
        }
        return ary.concat(node.pathLabels);
      }, []);
      return keywords.some((str) => normalize(str).includes(normalize(keyword)));
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [ARCHIVE_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // bff_ar_1 アーカイブ一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/archives/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined }
          }
        };

        $api.request(params)
        .then(res => {
          resolve(res.archiveList);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    },
    // 検索の初期化フラグを返却します
    // eslint-disable-next-line no-unused-vars
    initialFlag(queries) {
      // 当分の間初期検索条件はなし
      return INITIAL_VIEW_FLG.OFF;
      // return _.every(queries, (value, key) => {
      //   if (key === 'latestVersionOnly') {
      //     return !value;
      //   }
      //   return !_.isNumber(value) && _.isEmpty(value);
      // }) ? 1 : 0;
    },
    resetProcessUpdateDate() {
      // プロセス更新日時 検索初期条件：Start Date 当日を含んで181日前～ End Date 当日
      // Clearボタン押下で検索条件を削除しデフォルトに戻す
      const today = dayjs().hour(0).minute(0).second(0);
      const endDay = today.add(1, 'd').subtract(1, 's');
      // console.log(today.format(), endDay.format(), today.subtract(180, 'd').format());
      this.searchForm.processUpdateDate = [
        today.subtract(180, 'd').format(),
        endDay.format(),
      ];
    },
    // プロセス更新日時のstartDate選択時に値を保持
    onPick(dates) {
      if (dates.maxDate) this.processUpdateMinDate = null;
      else this.processUpdateMinDate = dates.minDate;
      // console.log(this.processUpdateMinDate)
    },
    // プロセス更新日時は181日以上のRange選択を不可にする
    disabledDate(date) {
      if (this.processUpdateMinDate) {
        // console.log(this.checkDateRange(this.processUpdateMinDate, date, 180));
        return this.checkDateRange(this.processUpdateMinDate, date, 180);
      }
      return false;
    },
    // DateRange日数チェック @param 基準日 対象日 差分日数
    checkDateRange(refDate, targetDate, day) {
      const startDate = dayjs(refDate);
      const endDate = dayjs(targetDate);
      const diff = endDate.diff(startDate, 'day');
      // console.log(startDate.format(), endDate.format(), diff)
      return diff > day || -diff > day;
    },
    // プロセス更新日時バリデーションチェック
    checkProcessUpdateDate(rule, value, callback) {
      const startDate = value[0];
      const endDate = value[1];
      if (this.checkDateRange(startDate, endDate, 180)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  &.is-error {
    padding-bottom: 12px;
  }
}
</style>
<style lang="scss">
.el-popper.process_update_date {
  .el-date-table td.disabled:hover:after {
    display: block;
    position: absolute;
    width: auto;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    z-index: 3;
    text-align: center;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    background: #5E5873;
    padding: 1px 8px;
    border-radius: 11px;
    white-space: nowrap;
  }
  .el-date-table td.disabled:hover::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    bottom: calc(100% - 5px);
    margin: 0 auto;
    z-index: 2;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 8px solid #5E5873;
    border-bottom: 0;
  }
}
</style>
