import _ from 'lodash';
import { entityNames, processNames } from '@/dictionaries/map.js';

/**
 * エンティティ用 Mixin
 */
export default {
  methods: {
    // 特定のエンティティに対するプロセスを返却します
    getProcessesByEntity(entity) {
      if(!entity) return []
      return _.map(_.filter(processNames, {parent: entity}), p => {
        return Object.assign(p, {name: this.$t(`Process.${p.processType.replace('PT_', '').toUpperCase()}`)})
      })
    },
    // 指定した processType を持つプロセスのプロセスIDを返却します
    getProcessIdsByProcessType(processType) {
      if(!processType) return []
      return _.map(_.filter(processNames, {processType: processType}), p => p.processIds)
    },
    // 指定した processType を持ち、所属するエンティティの code が指定したものであるプロセスのプロセスIDを返却します
    getProcessIdsByProcessTypeAndEntityCode(processType, entityCode) {
      if(!processType || !entityCode) return []
      const parentEntityId = _.find(_.filter(entityNames, {code: entityCode}), e => e.entityId).entityId
      return _.flatten(_.map(_.filter(processNames, {parent: parentEntityId, processType: processType}), p => p.processIds))
    }
  }
};
